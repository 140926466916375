body {
	background: $color__background-body; // Fallback for when there is no custom background color defined.
}
/* wordpress admin styles */
@media (max-width: 782px) {

	.logged-in.admin-bar .site-header,
	.logged-in.admin-bar .offcanvas {
		top: 46px;
	}

	#wpadminbar {
		position: fixed;
	}
}

@media (min-width: 783px) {

	.logged-in.admin-bar .site-header,
	.logged-in.admin-bar .offcanvas {
		top: 32px;
	}
}