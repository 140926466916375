/*!
Base SASS setup - non WP projects
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Generic
	- Normalize
	- Box sizing
# Base
	- Typography
	- Elements
	- Links
	- Forms
# Utilities
	- Accessibility
	- Alignments

--------------------------------------------------------------*/

// Import variables and mixins.
@import "abstracts/abstracts";

/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/

/* Normalize
--------------------------------------------- */
@import "generic/normalize";

/* Box sizing
--------------------------------------------- */
@import "generic/box-sizing";

/* Bootstrap5
--------------------------------------------- */
@import "bootstrap5/bootstrap";

/* Icomoon
--------------------------------------------- */
@import "utilities/icomoon";


/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "base/base";

/* Accessibility
--------------------------------------------- */
@import "utilities/accessibility";

/* Alignments
--------------------------------------------- */
@import "utilities/alignments";

@import "theme/theme";
