a {
	color: var(--wv-link-color);
	text-decoration: var(--wv-link-textdecoration);
	&:visited {
		color: var(--wv-link-visited);
	}

	&:hover,
	&:focus,
	&:active {
		color: var(--wv-link-hover)
	}

	&:focus {
		outline: var(--wv-link-focus);
	}

	&:hover,
	&:active {
		outline: 0;
	}
}
