:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $name, $value in $settings-root {
    --wv-#{$name}: #{$value};
  }
}
  html::-webkit-scrollbar {
  width: 1em;
}
html::-webkit-scrollbar-thumb {
  background-color: var(--wv-scrollbar-color);
  border-radius: 10rem;
  border: 1px solid var(--wv-scrollbar-border-color);
}


html::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

html::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

::-moz-selection {
  color: var(--wv-primary-contrast);
  background-color: var(--wv-primary-color);
}

::selection {
  color: var(--wv-primary-contrast);
  background-color: var(--wv-primary-color);
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.top-button {
  background-color: var(--wv-primary-color);
  position: fixed;
  bottom: #{$grid-gutter-width};
  right: #{$grid-gutter-width};
  opacity: 0;
  transition: opacity 1s;
  z-index: 1090;
  width: 40px;
  height: 40px;
  text-align: center;
  &.visible {
    opacity: 1;
  }
  a {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    &:after {
      @include icon('\e907');
      color: var(--wv-primary-contrast);
      vertical-align: bottom;
      @include font-size(20px);
    }
  }

}