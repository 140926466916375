@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?m57aph');
  src:  url('../fonts/icomoon.eot?m57aph#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?m57aph') format('truetype'),
  url('../fonts/icomoon.woff?m57aph') format('woff'),
  url('../fonts/icomoon.svg?m57aph#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-right:before {
  content: "\e904";
}
.icon-chevron-left:before {
  content: "\e905";
}
.icon-chevron-down:before {
  content: "\e906";
}
.icon-chevron-up:before {
  content: "\e907";
}
.icon-heart:before {
  content: "\e900";
}
.icon-search:before {
  content: "\e901";
}
.icon-bars:before {
  content: "\e902";
}
.icon-navicon:before {
  content: "\e902";
}
.icon-reorder:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-remove:before {
  content: "\e903";
}
.icon-times:before {
  content: "\e903";
}
