body,
button,
input,
select,
optgroup,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	font-size: 1rem;
	line-height: $font__line-height-body;
}

@import "headings";
@import "copy";
