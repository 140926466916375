
.container {
  max-width: 1140px;
}
#header-container {
  padding: 30px 0;
  a {
    display: block;
  }
}

main {
  background-color: #F2F2F2;
  padding: 30px 0;
}

#footer-container {
  background-color: #1F1F1F;
  color: #848484;
  padding: 60px 0;
  a {
    color: #848484;
  }
  h4 {
    font-size: 2.0rem;
    font-weight: bold;
    color: #848484;
    margin: 30px 0;
  }
  ul {
    list-style-image: url(../images/list-image.svg);
    margin: 0;
    padding: 0 0 0 20px;
    li {
      position: relative;
      margin: 20px 0;
      padding-left: 10px;
      a {
        color: #848484;
        font-weight: bold;
        line-height: 1.3rem;
      }
    }
  }
}

label.error {
  @extend .alert-danger;
}

.form-control {
  &.error {
    @extend .is-invalid;
  }
}